import { Typography } from '@material-ui/core';
import React from 'react';
import constants from '../../builders/pizza/constants';
import styles from './CaloriesDisclaimer.styles';
import useSodiumAlertForStore from '@/clientCore/components/SodiumWarningDisclaimer/hooks/useSodiumAlertForStore';

const CaloriesDisclaimer = (): JSX.Element => {
  const classes = styles();
  const { isShowSodiumAlertForStore } = useSodiumAlertForStore();
  return (
    <>
      <Typography
        className={isShowSodiumAlertForStore ? classes.calorieDisclaimerWithLessMargin : classes.calorieDisclaimer}
        data-testid="calories-disclaimer"
      >
        {constants.NUTRITION_INFO_MSG}
      </Typography>
    </>
  );
};

export default CaloriesDisclaimer;
