import React from 'react';
import { Typography } from '@material-ui/core';
import { SodiumWarningIcon } from '@/clientCore/components/SodiumWarningDisclaimer/components/SodiumWarningIcon';
import styles from '@/clientCore/components/SodiumWarningDisclaimer/SodiumWarningDisclaimer.styles';
import { SODIUM_WARNING_DISCLAIMER } from '@/clientCore/components/SodiumWarningDisclaimer/constants';

export const SodiumWarningDisclaimer = (): JSX.Element => {
  const classes = styles();
  return (
    <>
      <SodiumWarningIcon width="97" height="12" />
      <Typography
        className={classes.SodiumWarningDisclaimer}
        data-testid="sodium-warning-disclaimer"
      >
        {`: ${SODIUM_WARNING_DISCLAIMER}`}
      </Typography>
    </>
  );
};
