import { makeStyles } from '@material-ui/core';
import colors from '../../../common/colors';
import fontStyles from '../../../common/fontStyles';

const styles = makeStyles((theme) => ({
  SodiumWarningDisclaimer: {
    ...fontStyles.bodySmall,
    margin: '0 0 64px',
    color: colors.gray600,
    display: 'inline'
  }
}));

export default styles;
