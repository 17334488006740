import { makeStyles } from '@material-ui/core';
import colors from '../colors';
import fontStyles from '../fontStyles';

const styles = makeStyles((theme) => ({
  calorieDisclaimer: {
    ...fontStyles.bodySmall,
    margin: '18px 0 64px',
    color: colors.gray600
  },
  calorieDisclaimerWithLessMargin: {
    ...fontStyles.bodySmall,
    margin: '18px 0 16px',
    color: colors.gray600
  }
}));

export default styles;
